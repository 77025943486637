<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link :class="{
                active:
                  $route.name === 'kpi-by-branch-view'
              }" class="navbar-nav-link"
                                 to="/kpi-by-branch">
                        Branch
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link :class="{
                active: 
                  $route.name === 'kpi-by-position-view'
              }" class="navbar-nav-link"
                                 to="/kpi-by-position">
                        Position
                    </router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <breadcrumb-component :links="[{name:'KPI'},{name:'Position'}]"></breadcrumb-component>
                    <div class="row mb-1">
                        <div class="col-md-6 mb-1"></div>
                        <div class="col-md-6 mb-1 col-6">
                            <div class="row">
                                <div class="col-md-6">
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group selectdiv">
                                        <select @change="filterDropdown" class="form-control" v-model="model.tahun">
                                            <template v-for="year in model.years">
                                                <option :key="year.id">{{ year.tahun }}</option>
                                            </template>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <button
                                            @click="createKpi"
                                            class="btn bg-green-700 btn-block text-white rounded-round btn-labeled btn-labeled-left"
                                    >
                                        <b>
                                            <i class="fas fa-plus"></i>
                                        </b>
                                        Add KPI
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th class="text-muted font-weight-bold">Month</th>
                                        <th class="text-muted font-weight-bold">Year</th>
                                        <th class="text-muted font-weight-bold">Active Employee</th>
                                        <th class="text-muted font-weight-bold">KPI Set</th>
                                        <th class="text-muted font-weight-bold"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-if="loading">
                                        <tr :key="a" v-for="a in 12">
                                            <td :key="b" v-for="b in 5">
                                                <content-placeholders :rounded="true">
                                                    <content-placeholders-text :lines="1"/>
                                                </content-placeholders>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr :key="index" v-for="(kpi,index) in kpis">
                                            <td>{{ kpi.month_name }}</td>
                                            <td>{{ kpi.year }}</td>
                                            <td>{{ kpi.active }}</td>
                                            <td>{{ kpi.kpi_set }}</td>
                                            <td class="text-right">
                                                <div class="list-icons">
                                                    <div class="list-icons-item dropdown">
                                                        <a
                                                                class="list-icons-item dropdown-toggle caret-0"
                                                                data-toggle="dropdown"
                                                                href="javascript:void(0)"
                                                        >
                                                            <i class="fas fa-ellipsis-v text-green-700 fa-2x"></i>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-right">
                                                            <router-link
                                                                    :to="`/kpi-by-position/${kpi.year}/${kpi.month}`"
                                                                    class="dropdown-item"
                                                            >
                                                                <i class="icon-file-stats"></i>
                                                                Edit KPI
                                                            </router-link>
                                                            <a
                                                                    @click="cloneKPI(model.tahun,kpi.month)"
                                                                    class="dropdown-item"
                                                                    href="javascript:void(0)"
                                                            >
                                                                <i class="far fa-copy"></i>
                                                                Duplicate KPI
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
                :hide-footer="true"
                :no-close-on-backdrop="true"
                :no-close-on-esc="true"
                header-class="bg-white"
                id="createKpi"
                ref="createKpi"
                title="Add KPI"
        >
            <form @submit.prevent="submitUpdateForm">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <label for="year">Year</label>
                        <input class="form-control" id="year" type="text" v-model="createModel"/>
                    </div>
                </div>
                <div class="form-group">
                </div>
                <button class="btn bg-green-700 btn-block mt-3" type="submit">Submit</button>
            </form>
        </b-modal>

        <b-modal
                :hide-footer="true"
                :no-close-on-backdrop="true"
                :no-close-on-esc="true"
                header-class="bg-white"
                id="cloneKPI"
                ref="cloneKPI"
                title="Duplicate KPI"
        >
            <form @submit.prevent="submitCloneForm">
                <div class="row">
                    <div class="col-md-12 mb-2">
                        <label for="year">From</label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group selectdiv">
                                    <select class="form-control" disabled v-model="cloneModel.from_month">
                                        <option value="1">Januari</option>
                                        <option value="2">Februari</option>
                                        <option value="3">Maret</option>
                                        <option value="4">April</option>
                                        <option value="5">Mei</option>
                                        <option value="6">Juni</option>
                                        <option value="7">Juli</option>
                                        <option value="8">Agustus</option>
                                        <option value="9">September</option>
                                        <option value="10">Oktober</option>
                                        <option value="11">November</option>
                                        <option value="12">Desember</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input class="form-control" disabled id="year" type="text" v-model="cloneModel.from_year"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-2">
                        <label for="year">To</label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group selectdiv">
                                    <select class="form-control" v-model="cloneModel.to_month">
                                        <option value="1">Januari</option>
                                        <option value="2">Februari</option>
                                        <option value="3">Maret</option>
                                        <option value="4">April</option>
                                        <option value="5">Mei</option>
                                        <option value="6">Juni</option>
                                        <option value="7">Juli</option>
                                        <option value="8">Agustus</option>
                                        <option value="9">September</option>
                                        <option value="10">Oktober</option>
                                        <option value="11">November</option>
                                        <option value="12">Desember</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group selectdiv">
                                    <select class="form-control" v-model="cloneModel.to_year">
                                        <template v-for="year in model.years">
                                            <option :key="year.id">{{ year.tahun }}</option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                </div>
                <button class="btn bg-green-700 btn-block mt-3" type="submit">Submit</button>
            </form>
        </b-modal>
    </div>
</template>
<script>
    import KpiApi from '../apis/Kpi'
    import Hrms from '../services/hrms'

    export default {
        data() {
            return {
                loading: true,
                pagination: {
                    totalPage: 10,
                    currentPage: 1,
                    totalData: 0,
                    displaying: 0
                },
                model: {
                    years: [],
                    bulan: new Date().getMonth() + 1,
                    tahun: new Date().getFullYear()
                },
                createModel: '',
                cloneModel: {
                    from_year: '',
                    from_month: '',
                    to_year: '',
                    to_month: 1,
                },
                kpis: []
            }
        },
        components: {
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    )
        },
        methods: {
            submitCloneForm(e) {
                KpiApi
                    .cloneKpiByMonthYear(
                        this.cloneModel.from_year,
                        this.cloneModel.from_month,
                        this.cloneModel.to_year,
                        this.cloneModel.to_month
                    )
                    .then(res => {
                        this.loading = true
                        this.$refs['cloneKPI'].hide()
                        this.kpiGroupByMonthYearAction(this.model.tahun)
                        console.log(res)
                    })
                    .catch(err => {
                        this.$refs['cloneKPI'].hide()
                        console.log(err)
                    })
            },
            cloneKPI(tahun, bulan) {
                this.cloneModel.from_year = tahun
                this.cloneModel.from_month = bulan
                this.cloneModel.to_year = tahun
                this.$refs['cloneKPI'].show()
            },
            createKpi(e) {
                this.createModel = this.model.tahun
                this.$refs['createKpi'].show()
            },
            submitUpdateForm(e) {
                let payload = {
                    tahun: this.createModel
                }
                KpiApi
                    .store(payload)
                    .then(res => {
                        this.kpiSearch({limit: -1, sort: 3, order: 'tahun'})
                        this.loading = true
                        this.$refs['createKpi'].hide()
                        this.kpiGroupByMonthYearAction(this.model.tahun)
                        console.log(res)
                    })
                    .catch(err => {
                        this.kpiSearch({limit: -1, sort: 3, order: 'tahun'})
                        this.$refs['createKpi'].hide()
                        console.log(err)
                    })
            },
            kpiSearch(param) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-KPI-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                KpiApi.search(param)
                    .then(res => {
                        this.model.years = res.data.data
                    })
                    .catch(error => {

                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-KPI-002]',
                            'error'
                        )
                    })
            },
            filterDropdown(e) {
                this.loading = true
                this.kpiGroupByMonthYearAction(this.model.tahun)
            },
            kpiGroupByMonthYearAction(year) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-KPI-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                KpiApi.groupByMonthYearAction(year)
                    .then(res => {
                        this.kpis = res.data.data
                        console.log(this.kpis)
                        this.loading = false
                    })
                    .catch(error => {

                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-KPI-002]',
                            'error'
                        )
                    })
            }
        },
        mounted() {
            document.title = 'KPI Management'
            this.kpiSearch({limit: -1, sort: 3, order: 'tahun'})
            this.kpiGroupByMonthYearAction(this.model.tahun)
        }
    }
</script>
