<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item" :class="{active:$route.params.id==5}">
          <router-link class="navbar-nav-link" to="/league-table/5">BM</router-link>
        </li>
        <li class="nav-item" :class="{active:$route.params.id==6}">
          <router-link class="navbar-nav-link" to="/league-table/6">RM</router-link>
        </li>
        <li class="nav-item" :class="{active:$route.params.id==43}">
          <router-link class="navbar-nav-link" to="/league-table/43">RFM</router-link>
        </li>
        <li class="nav-item" :class="{active:$route.params.id==8}">
          <router-link class="navbar-nav-link" to="/league-table/8">FM</router-link>
        </li>
        <li class="nav-item" :class="{active:$route.params.id==426}">
          <router-link class="navbar-nav-link" to="/league-table/426">TEAM LEADER</router-link>
        </li>
        <li class="nav-item" :class="{active:$route.params.id==10}">
          <router-link class="navbar-nav-link" to="/league-table/10">CFM</router-link>
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label>From</label>
                    <datepicker :input-class="'form-control'" id="from_date" v-model="filter.from_date" :format="ymd" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>To</label>
                    <datepicker :input-class="'form-control'" id="to_date" v-model="filter.to_date" :format="ymd" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="branch_id">Position</label>
                    <model-select :options="table_views" placeholder="select item" v-model="filter.table_view">
                    </model-select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="branch_id">Product Type</label>
                    <model-select :options="model.product_type" id="product_type" placeholder="select item"
                      v-model="filter.product_type"></model-select>
                  </div>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-md-2">
                  <button class="btn btn-warning btn-block" @click="resetFilter">Reset</button>
                </div>
                <div class="col-md-2">
                  <button class="btn btn-success btn-block" @click="submitFilter">Filter</button>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th v-for="(table,index) in table_config[filter.table_view].columns.headers" :key="index">
                      {{table.label}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="loading">
                    <tr :key="n" v-for="n in table_config[filter.table_view].columns.headers.length">
                      <td :key="m" v-for="m in 12">
                        <content-placeholders>
                          <content-placeholders-heading :img="true" />
                        </content-placeholders>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="data in datas" :key="data.no">
                      <td v-for="(table,index) in table_config[filter.table_view].columns.headers" :key="index">
                        <template v-if="data.juara">
                          <template v-if="table.column == 'name'">
                            <img src="https://delight.mifx.com/template/images/ico-winner.svg?20210223142022" alt=""
                              srcset="">
                          </template>
                        </template>
                        <span class="" :class="{'d-block':table.column!='name'}">
                          <template
                            v-if="table.column == 'name' || table.column == 'no' || table.column == 'branch_name'">
                            {{data[table.column]}}
                          </template>
                          <template v-else>
                            {{new Intl.NumberFormat('id-ID', {}).format(data[table.column]) }}
                          </template>
                        </span>
                        <template v-if="table.target">
                          <span>KPI: {{new Intl.NumberFormat('id-ID', {}).format(data[table.target]) }}</span>
                        </template>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BackofficeApi from '../apis/Backoffice'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import { ModelSelect } from 'vue-search-select'
export default {
  data() {
    return {
        loading:true,
        table_views:[
          {
              value: 0,
              text: '5 wheels'
          },
          {
              value: 1,
              text: 'Acquisition'
          },
          {
              value: 2,
              text: 'Retention'
          },
          {
              value: 3,
              text: 'Referral'
          }
        ],
        datas:[],
        filter:{
            to_date:(new Date()).getFullYear()+'-'+String((new Date()).getMonth() + 1).padStart(2, '0')+'-'+String((new Date()).getDate()).padStart(2, '0'),
            from_date:(new Date()).getFullYear()+'-'+String((new Date()).getMonth() + 1).padStart(2, '0')+'-01',
            id:this.$route.params.id,
            table_view:0
        },
        model:{
          product_type:[
            {
              value: '',
              text: 'All'
            },
            {
              value:'MULTILATERAL',
              text: 'MULTILATERAL'
            },
            {
              value:'SPA',
              text: 'SPA'
            }
          ]
        },
        table_config:[
          {
            columns:{
              headers:[
                {
                  label:'No',
                  column:'no',
                  target:false
                },
                {
                  label:'Sales Name',
                  column:'name',
                  target:false
                },
                {
                  label:'Branch',
                  column:'branch_name',
                  target:false
                },
                {
                  label:'NC/KPI	',
                  column:'total_nc',
                  target:'target_nc'
                },
  {
                  label:'NC Count/KPI	',
                  column:'total_nc_count',
                  target:'target_nc_count'
                },
                {
                  label:'MANC/KPI',
                  column:'total_ma_nc',
                  target:'target_ma_nc'
                },
                {
                  label:'NMI/KPI',
                  column:'total_net_achievement',
                  target:'target_net_achievement'
                },
                {
                  label:'LOT/KPI',
                  column:'total_lot',
                  target:'target_lot'
                },
                {
                  label:'ATA/KPI',
                  column:'total_active_trading_account',
                  target:'target_active_trading_account'
                },
                {
                  label:'Score',
                  column:'score',
                  target:false
                },
                {
                  label:'Lot 10/12',
                  column:'total_lot_10_12',
                  target:false
                },
                {
                  label:'Lot 14 USD',
                  column:'total_lot_14_usd',
                  target:false
                },
                {
                  label:'Total Score',
                  column:'total_score',
                  target:false
                }
              ]
            }
          },
          {
            columns:{
              headers:[
                {
                  label:'No',
                  column:'no',
                  target:false
                },
                {
                  label:'Sales Name',
                  column:'name',
                  target:false
                },
                {
                  label:'Branch',
                  column:'branch_name',
                  target:false
                },
                {
                  label:'NC/KPI	',
                  column:'total_nc',
                  target:'target_nc'
                },
                {
                  label:'NC Count/KPI	',
                  column:'total_nc_count',
                  target:'target_nc_count'
                },
                {
                  label:'MANC/KPI',
                  column:'total_ma_nc',
                  target:'target_ma_nc'
                },
                {
                  label:'NMI/KPI',
                  column:'total_net_achievement',
                  target:'target_net_achievement'
                },
                {
                  label:'MANA/KPI',
                  column:'total_ma_na',
                  target:'target_ma_na'
                },
                {
                  label:'Score',
                  column:'score',
                  target:false
                },
                {
                  label:'Lot 10/12',
                  column:'total_lot_10_12',
                  target:false
                },
                {
                  label:'Lot 14 USD',
                  column:'total_lot_14_usd',
                  target:false
                },
                {
                  label:'Total Score',
                  column:'total_score',
                  target:false
                }
              ]
            }
          },
          {
            columns:{
              headers:[
                {
                  label:'No',
                  column:'no',
                  target:false
                },
                {
                  label:'Sales Name',
                  column:'name',
                  target:false
                },
                {
                  label:'Branch',
                  column:'branch_name',
                  target:false
                },
                {
                  label:'NMI/KPI',
                  column:'total_net_achievement',
                  target:'target_net_achievement'
                },
                {
                  label:'LOT/KPI',
                  column:'total_lot',
                  target:'target_lot'
                },
                {
                  label:'ATA/KPI',
                  column:'total_active_trading_account',
                  target:'target_active_trading_account'
                },
                {
                  label:'Score',
                  column:'score',
                  target:false
                },
                {
                  label:'Lot 10/12',
                  column:'total_lot_10_12',
                  target:false
                },
                {
                  label:'Lot 14 USD',
                  column:'total_lot_14_usd',
                  target:false
                },
                {
                  label:'Total Score',
                  column:'total_score',
                  target:false
                }
              ]
            }
          },
          {
            columns:{
              headers:[
                {
                  label:'No',
                  column:'no',
                  target:false
                },
                {
                  label:'Sales Name',
                  column:'name',
                  target:false
                },
                {
                  label:'Branch',
                  column:'branch_name',
                  target:false
                },
                {
                  label:'NR/KPI',
                  column:'total_new_referral',
                  target:'target_new_referral'
                },
                {
                  label:'NC/KPI',
                  column:'total_nc',
                  target:'target_nc'
                },
                {
                  label:'NC Count/KPI	',
                  column:'total_nc_count',
                  target:'target_nc_count'
                },
                {
                  label:'MANC/KPI',
                  column:'total_ma_nc',
                  target:'target_ma_nc'
                },
                {
                  label:'NMI/KPI',
                  column:'total_net_achievement',
                  target:'target_net_achievement'
                },
                {
                  label:'LOT/KPI',
                  column:'total_lot',
                  target:'target_lot'
                },
                {
                  label:'AR/KPI',
                  column:'total_active_referral',
                  target:'target_active_referral'
                },
                {
                  label:'Score',
                  column:'score',
                  target:false
                },
                {
                  label:'Lot 10/12',
                  column:'total_lot_10_12',
                  target:false
                },
                {
                  label:'Lot 14 USD',
                  column:'total_lot_14_usd',
                  target:false
                },
                {
                  label:'Total Score',
                  column:'total_score',
                  target:false
                }
              ]
            }
          }
        ]
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    Datepicker,
    ModelSelect
  },
  methods: {
    resetFilter(){
      this.loading=true
      this.filter.table_view=0
      this.filter.to_date=(new Date()).getFullYear()+'-'+String((new Date()).getMonth() + 1).padStart(2, '0')+'-'+String((new Date()).getDate()).padStart(2, '0')
      this.filter.from_date=(new Date()).getFullYear()+'-'+String((new Date()).getMonth() + 1).padStart(2, '0')+'-01'
      this.leagueTableListByOccupation(this.filter)
    },
    submitFilter(){
      this.loading=true
      this.filter.to_date=this.dateToDatabaseFormat(this.filter.to_date)
      this.filter.from_date=this.dateToDatabaseFormat(this.filter.from_date)
      this.leagueTableListByOccupation(this.filter)
    },
    ymd(date) {
      return moment(date).format('YYYY-MM-DD');
    },
      leagueTableListByOccupation(query){
          BackofficeApi
          .leagueTableListByOccupation(query)
          .then(res=>{
            console.log(res)
            this.datas = res.data.data.map(function(data,index){
                return {
                    no:index+1,
                    name:data.first_name,
                    branch_name:data.branch_name,

                    target_nc:data.target_nc,
                    total_nc:data.total_nc.toFixed(2),

                    target_nc_count:data.target_nc_count,
                    total_nc_count:data.total_nc_count.toFixed(2),

                    target_ma_nc:data.target_ma_nc,
                    total_ma_nc:data.total_ma_nc.toFixed(2),

                    total_ma_na:data.total_ma_na,
                    target_ma_na:data.target_ma_na,
                    
                    target_active_referral:data.target_active_referral,
                    total_active_referral:data.total_active_referral,

                    target_new_referral:data.target_new_referral,
                    total_new_referral:data.total_new_referral,

                    target_net_achievement:data.target_net_achievement,
                    total_net_achievement:data.total_net_achievement.toFixed(2),

                    total_lot:data.total_lot.toFixed(2),
                    target_lot:data.target_lot,

                    total_lot_10_12:data.total_lot_10_12.toFixed(2),
                    total_lot_14_usd:data.total_lot_14_usd.toFixed(2),

                    total_active_trading_account:data.total_active_trading_account.toFixed(2),
                    target_active_trading_account:data.target_active_trading_account,

                    total_score:data.total_score.toFixed(2),
                    score:data.score.toFixed(2),
                    juara:false
                }
            })
            this.datas.sort(this.fieldSorterOptimized(['-score','-total_score']))
            this.datas = this.datas.map(function(data,index){
                let juara = false
                if((index+1) == 1){
                    juara=true
                }
                return {
                    no:(index+1),
                    name:data.name,
                    branch_name:data.branch_name,

                    target_nc:data.target_nc,
                    total_nc:data.total_nc,

                    target_nc_count:data.target_nc_count,
                    total_nc_count:data.total_nc_count,

                    target_ma_nc:data.target_ma_nc,
                    total_ma_nc:data.total_ma_nc,

                    total_ma_na:data.total_ma_na,
                    target_ma_na:data.target_ma_na,
                    
                    target_active_referral:data.target_active_referral,
                    total_active_referral:data.total_active_referral,

                    target_new_referral:data.target_new_referral,
                    total_new_referral:data.total_new_referral,

                    target_net_achievement:data.target_net_achievement,
                    total_net_achievement:data.total_net_achievement,

                    total_lot:data.total_lot,
                    target_lot:data.target_lot,

                    total_lot_10_12:data.total_lot_10_12,
                    total_lot_14_usd:data.total_lot_14_usd,

                    total_active_trading_account:data.total_active_trading_account,
                    target_active_trading_account:data.target_active_trading_account,
                    total_score:data.total_score,
                    score:data.score,
                    juara:juara
                }
            })
            console.log(this.datas)
            this.loading=false
          })
          .catch(res=>{
              console.log(res)
          })
      },
    dateToDatabaseFormat(value) {
      return moment(String(value)).format('YYYY-MM-DD')
    },
    fieldSorterOptimized(fields) {
    var dir = [], i, l = fields.length;
    fields = fields.map(function(o, i) {
        if (o[0] === "-") {
        dir[i] = -1;
        o = o.substring(1);
        } else {
        dir[i] = 1;
        }
        return o;
    });

    return function (a, b) {
        for (i = 0; i < l; i++) {
        var o = fields[i];
        if (a[o] > b[o]) return dir[i];
        if (a[o] < b[o]) return -(dir[i]);
        }
        return 0;
    };
    }
  },
  mounted() {
    document.title = 'League Table'
    this.leagueTableListByOccupation(this.filter)
  },
    beforeRouteUpdate(to, from, next) {
        // this.viewEmployee(to.params.id)
        this.loading=true
        this.filter.id = to.params.id
        this.leagueTableListByOccupation(this.filter)
        next()
    }
}
</script>
