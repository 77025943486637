<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link class="navbar-nav-link" to="/kpi-by-branch">Edit Branch KPI</router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <breadcrumb-component :links="[{name:'KPI'},{name:'Branch'},{name:'Edit KPI '+model.bulan+'-'+model.tahun}]"></breadcrumb-component>
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group selectdiv">
                                <select @change="filterDropdown" class="form-control" v-model="model.bulan">
                                    <option value="1">Januari</option>
                                    <option value="2">Februari</option>
                                    <option value="3">Maret</option>
                                    <option value="4">April</option>
                                    <option value="5">Mei</option>
                                    <option value="6">Juni</option>
                                    <option value="7">Juli</option>
                                    <option value="8">Agustus</option>
                                    <option value="9">September</option>
                                    <option value="10">Oktober</option>
                                    <option value="11">November</option>
                                    <option value="12">Desember</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group selectdiv">
                                <select @change="filterDropdown" class="form-control" v-model="model.tahun">
                                    <template v-for="year in model.years">
                                        <option :key="year.id">{{ year.tahun }}</option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">

                        </div>
                        <div class="col-md-2">
                            --
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="card">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>Branch Name</th>
                                        <th>KPI NR</th>
                                        <th>KPI AR</th>
                                        <th>KPI NC</th>
                                        <th>KPI NC Count</th>
                                        <th>KPI MA</th>
                                        <th>KPI NMI</th>
                                        <th>KPI Lot</th>
                                        <th>KPI ATA</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <template v-if="loading">
                                        <tr :key="a" v-for="a in 12">
                                            <td :key="b" v-for="b in 9">
                                                <content-placeholders :rounded="true">
                                                    <content-placeholders-text :lines="1"/>
                                                </content-placeholders>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-for="branch in branches" v-if="!loading">
                                        <tr :key="branch.id">
                                            <td>{{branch.branch_name}}</td>
                                            <td>{{branch.kpi.nr}}</td>
                                            <td>{{branch.kpi.ar}}</td>
                                            <td>{{branch.kpi.nc}}</td>
                                            <td>{{branch.kpi.nc_count}}</td>
                                            <td>{{branch.kpi.ma_nc}}</td>
                                            <td>{{branch.kpi.nmi}}</td>
                                            <td>{{branch.kpi.lot}}</td>
                                            <td>{{branch.kpi.ata}}</td>
                                            <td class="text-right">
                                                <div class="list-icons">
                                                    <div class="list-icons-item dropdown">
                                                        <a
                                                                class="list-icons-item dropdown-toggle caret-0"
                                                                data-toggle="dropdown"
                                                                href="javascript:void(0)"
                                                        >
                                                            <i class="fas fa-ellipsis-v text-green-700 fa-2x"></i>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-right">
                                                            <a @click="saveOrUpdate($event,branch)" class="dropdown-item" href="javascript:void(0)">
                                                                <i class="fas fa-pencil-alt"></i>
                                                                Edit KPI
                                                            </a>
                                                            <a @click="copyKpi($event,branch)" class="dropdown-item" href="javascript:void(0)">
                                                                <i class="far fa-copy"></i>
                                                                Copy KPI
                                                            </a>
                                                            <a @click="pasteKpi($event,branch)" class="dropdown-item" href="javascript:void(0)">
                                                                <i class="fas fa-paste"></i>
                                                                Paste KPI
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
                :hide-footer="true"
                :no-close-on-backdrop="true"
                :no-close-on-esc="true"
                header-class="bg-white"
                id="createOrUpdate"
                ref="createOrUpdate"
                title="Update KPI"
        >
            <form @submit.prevent="submitUpdateForm">
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <label for="nr">NR</label>
                        <input class="form-control" id="nr" type="text" v-model="defaultValue.nr"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="ar">AR</label>
                        <input class="form-control" id="ar" type="text" v-model="defaultValue.ar"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="nc">NC</label>
                        <input class="form-control" id="nc" type="text" v-model="defaultValue.nc"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="ma_nc">MA</label>
                        <input class="form-control" id="ma_nc" type="text" v-model="defaultValue.ma_nc"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="nmi">NMI</label>
                        <input class="form-control" id="nmi" type="text" v-model="defaultValue.nmi"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="lot">LOT</label>
                        <input class="form-control" id="lot" type="text" v-model="defaultValue.lot"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="ata">ATA</label>
                        <input class="form-control" id="ata" type="text" v-model="defaultValue.ata"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="ata">NC COUNT</label>
                        <input class="form-control" id="nc_count" type="text" v-model="defaultValue.nc_count"/>
                    </div>
                </div>
                <div class="form-group">
                </div>
                <button class="btn bg-green-700 btn-block mt-3" type="submit">Submit</button>
            </form>
        </b-modal>

    </div>
</template>
<script>
    import KpiBranch from "../apis/KpiBranch";
    import Hrms from "../services/hrms";
    import KpiApi from "../apis/Kpi";

    export default {
        data() {
            return {
                loading: true,
                branches: [],
                model: {
                    years: [],
                    bulan: this.$route.params.month,
                    tahun: this.$route.params.year
                },
                defaultValue: {
                    id: null,
                    kpi_id: null,
                    nr: 0.00,
                    ar: 0.00,
                    nc: 0.00,
                    nc_count: 0.00,
                    ma_nc: 0.00,
                    nmi: 0.00,
                    lot: 0.00,
                    ata: 0.00,
                    created_by: null,
                    created_at: null,
                    updated_by: null,
                    updated_at: null,
                    branch_id: null,
                    occupation_id: null,
                    bulan: null,
                    tahun: null
                },
                kpi: {},
                modelCopy: {}
            }
        },
        components: {
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    )
        },
        methods: {
            filterDropdown(e) {
                document.title = 'KPI Management ' + this.model.tahun + '/' + this.model.bulan
                this.loading = true
                this.kpiSearch({limit: -1, sort: 3, order: 'tahun'})
                this.kpiBranchDetails(this.model.tahun, this.model.bulan)
            },
            copyKpi(e, d) {
                this.modelCopy = d.kpi
            },
            pasteKpi(e, d) {
                console.log(d)
                console.log(this.modelCopy)
                if (d.kpi.id) {
                    this.modelCopy.branch_id = d.branch_id
                    this.modelCopy.id = d.kpi.id
                    KpiBranch
                        .update(this.modelCopy, d.kpi.id)
                        .then(res => {
                            console.log(res)
                        })
                        .catch(res => {
                            console.log(res)
                        })
                } else {
                    delete this.modelCopy.id;
                    this.modelCopy.branch_id = d.branch_id
                    KpiBranch
                        .store(this.modelCopy)
                        .then(res => {
                            this.kpiBranchDetails(this.model.tahun, this.model.bulan)
                        })
                        .catch(res => {
                            console.log(res)
                            this.kpiBranchDetails(this.model.tahun, this.model.bulan)
                        })
                }
            },
            submitUpdateForm() {
                this.loading = true
                this.$refs['createOrUpdate'].hide()
                this.kpiBranchDetails(this.model.tahun, this.model.bulan)
                this.defaultValue.id = parseInt(this.defaultValue.id)
                this.defaultValue.kpi_id = parseInt(this.defaultValue.kpi_id)
                this.defaultValue.nr = parseFloat(this.defaultValue.nr)
                this.defaultValue.ar = parseFloat(this.defaultValue.ar)
                this.defaultValue.nc = parseFloat(this.defaultValue.nc)
                this.defaultValue.nc_count = parseFloat(this.defaultValue.nc_count)
                this.defaultValue.ma_nc = parseFloat(this.defaultValue.ma_nc)
                this.defaultValue.nmi = parseFloat(this.defaultValue.nmi)
                this.defaultValue.lot = parseFloat(this.defaultValue.lot)
                this.defaultValue.ata = parseFloat(this.defaultValue.ata)
                this.defaultValue.branch_id = parseInt(this.defaultValue.branch_id)
                this.defaultValue.occupation_id = 0
                this.defaultValue.bulan = parseInt(this.defaultValue.bulan)
                this.defaultValue.tahun = parseInt(this.defaultValue.tahun)

                if (this.defaultValue.id) {
                    //update
                    KpiBranch
                        .update(this.defaultValue, this.defaultValue.id)
                        .then(res => {
                            console.log(res)
                        })
                        .catch(res => {
                            console.log(res)
                        })
                } else {
                    delete this.defaultValue.id;
                    KpiBranch
                        .store(this.defaultValue)
                        .then(res => {
                            this.kpiBranchDetails(this.model.tahun, this.model.bulan)
                        })
                        .catch(res => {
                            console.log(res)
                            this.kpiBranchDetails(this.model.tahun, this.model.bulan)
                        })
                }
            },
            saveOrUpdate(e, b) {
                this.defaultValue = {...this.defaultValue, ...b.kpi}
                this.defaultValue.id = parseInt(this.defaultValue.id)
                this.defaultValue.kpi_id = parseInt(this.defaultValue.kpi_id)
                this.defaultValue.nr = parseFloat(this.defaultValue.nr)
                this.defaultValue.ar = parseFloat(this.defaultValue.ar)
                this.defaultValue.nc = parseFloat(this.defaultValue.nc)
                this.defaultValue.nc_count = parseFloat(this.defaultValue.nc_count)
                this.defaultValue.ma_nc = parseFloat(this.defaultValue.ma_nc)
                this.defaultValue.nmi = parseFloat(this.defaultValue.nmi)
                this.defaultValue.lot = parseFloat(this.defaultValue.lot)
                this.defaultValue.ata = parseFloat(this.defaultValue.ata)
                this.defaultValue.branch_id = parseInt(b.branch_id)
                this.defaultValue.occupation_id = parseInt(this.defaultValue.occupation_id)
                this.defaultValue.bulan = (this.defaultValue.bulan <= 0) ? parseInt(this.model.bulan) : parseInt(this.defaultValue.bulan)
                this.defaultValue.tahun = (this.defaultValue.tahun <= 0) ? parseInt(this.model.tahun) : parseInt(this.defaultValue.tahun)
                this.$refs['createOrUpdate'].show()
            },
            kpiBranchDetails(year, month) {
                KpiBranch
                    .kpiBranchDetails(year, month)
                    .then(res => {
                        this.branches = res.data.data
                        this.loading = false
                    })
                    .catch(res => {
                        console.log(res)
                    })
            },
            kpiSearch(param) {
                Hrms.interceptors.response.use(
                    response => response,
                    error => {
                        if (typeof error.response === 'undefined') {
                            this.$swal(
                                'Ooops!',
                                'A network error occurred. ' +
                                'This could be a CORS issue or a dropped internet connection. ' +
                                'It is not possible for us to know. [CE-KPI-001]',
                                'error'
                            )
                        }
                        return Promise.reject(error)
                    }
                )
                KpiApi.search(param)
                    .then(res => {
                        let that = this
                        this.model.years = res.data.data
                        res.data.data.forEach((v, i) => {
                            if (parseInt(that.model.tahun) === parseInt(v.tahun)) {
                                that.kpi = v
                            }
                        })
                    })
                    .catch(error => {
                        this.$swal(
                            'Ooops!',
                            'A network error occurred. ' +
                            'It is not possible for us to know. [CE-KPI-002]',
                            'error'
                        )
                    })
            }
        },
        mounted() {
            document.title = 'KPI Branch Management'
            this.kpiSearch({limit: -1, sort: 3, order: 'tahun'})
            this.kpiBranchDetails(this.model.tahun, this.model.bulan)
        }
    }
</script>
<style scoped>
    .table th,
    .table td {
        padding: 0.5rem 1rem;
    }
</style>