<template>
  <div>
    <Header>
      <template v-slot:nav-item-left>
        <li class="nav-item">
          <router-link class="navbar-nav-link" to="/departments">
            <i class="fas fa-chevron-left text-green-700"></i>
            Department List
          </router-link>
        </li>
      </template>
    </Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <div class="row mb-2">
            <div class="col-md-9 mb-1">
              <!-- Displaying
              <span class="font-weight-bold text-green-700">{{
                pagination.displaying
              }}</span>
              of
              <span class="font-weight-bold text-green-700">{{
                pagination.totalData
              }}</span>
              Result -->
            </div>
            <div class="col-md-3 mb-1">
              <b-button
                class="btn btn-primary btn-block rounded-round btn-labeled btn-labeled-left"
                v-b-modal="'addDepartment'"
              >
                <b>
                  <i class="icon-user-plus"></i>
                </b>
                Add New Department
              </b-button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="text-muted font-weight-bold">Name</th>
                      <th class="text-muted font-weight-bold">Manager</th>
                      <th width="50"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="loading">
                      <tr :key="a" v-for="a in 10">
                        <td :key="b" v-for="b in 3">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text :lines="1" />
                          </content-placeholders>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr
                        :key="department.id"
                        v-for="department in departments"
                      >
                        <td scope="row">{{ department.name }}</td>
                        <td>
                          <template
                            v-if="
                              typeof department.manager !== 'undefined' &&
                                department.manager
                            "
                          >
                            {{
                              department.manager.firstname +
                                ' ' +
                                department.manager.lastname
                            }}
                          </template>
                          <template v-else>-</template>
                        </td>
                        <td>
                          <router-link :to="`/departments/${department.id}`">
                            <i class="far fa-eye"></i>
                          </router-link>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <b-modal
          :hide-footer="true"
          :no-close-on-backdrop="true"
          :no-close-on-esc="true"
          header-class="bg-white"
          id="addDepartment"
          ref="addDepartment"
          title="Create Department"
        >
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <label for="wp_code">Department Name</label>
              <input
                class="form-control"
                id="wp_code"
                type="text"
                v-model="department.name"
              />
            </div>
            <div class="form-group">
              <label for>Manager</label>
              <model-select
                :options="employees"
                v-model="department.manager_id"
              />
            </div>
            <button class="btn bg-green-700 btn-block mt-3" type="submit">
              Submit
            </button>
          </form>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import EmployeeApi from '../apis/Employee'
import DepartmentApi from '../apis/Department'
import Hrms from '../services/hrms'
import { ModelListSelect, ModelSelect } from 'vue-search-select'

export default {
  data() {
    return {
      loading: true,
      departments: [],
      pagination: {
        totalPage: 0,
        currentPage: 1,
        totalData: 0,
        displaying: 0
      },
      department: {
        name: '',
        manager_id: {
          value: '',
          text: ''
        }
      },
      employees: []
    }
  },
  components: {
    ModelSelect,
    ModelListSelect,
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      )
  },
  methods: {
    submitForm(e) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.  [CE-OCCUPATIONSTORE-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      let payload = {
        name: this.department.name,
        manager_id: this.department.manager_id.value
      }
      DepartmentApi.store(payload)
        .then(result => {
          console.log(result)
          this.DepartmentSearch({ expand: 'manager' })
          this.$refs['addDepartment'].hide()
        })
        .catch(err => {
          console.log(err)
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'This could be a CORS issue or a dropped internet connection. ' +
              'It is not possible for us to know.  [CE-OCCUPATIONSTORE-002]',
            'error'
          )
        })
    },
    employeeSearch(param) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      EmployeeApi.search(param)
        .then(res => {
          this.employees = res.data.data.map(x => {
            return {
              value: x.id,
              text: x.firstname + ' ' + x.lastname + ' (' + x.email + ')'
            }
          })
        })
        .catch(error => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' + 'It is not possible for us to know.',
            'error'
          )
        })
    },
    DepartmentSearch(query) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know.  [CE-DEPARTMENT-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      DepartmentApi.search(query)
        .then(result => {
          console.log(result)
          this.departments = result.data.data

          this.pagination.totalPage = parseInt(
            result.headers['x-pagination-page-count']
          )
          this.pagination.currentPage = parseInt(
            result.headers['x-pagination-current-page']
          )
          this.pagination.totalData = parseInt(
            result.headers['x-pagination-total-count']
          )
          let perPage = parseInt(result.headers['x-pagination-per-page'])
          let display = perPage * this.pagination.currentPage
          if (display >= this.pagination.totalData) {
            this.pagination.displaying = this.pagination.totalData
          } else {
            this.pagination.displaying = display
          }
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'This could be a CORS issue or a dropped internet connection. ' +
              'It is not possible for us to know.  [CE-DEPARTMENT-002]',
            'error'
          )
        })
    }
  },
  mounted() {
    document.title = 'Department List'
    this.DepartmentSearch({ expand: 'manager', limit: -1 })
    this.employeeSearch({ limit: -1 })
  }
}
</script>
