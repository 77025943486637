<template>
  <div>
    <Header></Header>
    <div class="page-content">
      <Sidebar />
      <div class="content-wrapper">
        <div class="content">
          <breadcrumb-component
            :links="[{ name: 'Achievement Table' }]"
          ></breadcrumb-component>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="from_date">From</label>
                        <datepicker
                          :input-class="'form-control'"
                          id="from_date"
                          v-model="filter.from_date"
                          :format="ymd"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="to_date">To</label>
                        <datepicker
                          :input-class="'form-control'"
                          id="to_date"
                          v-model="filter.to_date"
                          :format="ymd"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="name">Sales</label>
                        <input
                          class="form-control"
                          id="name"
                          name="name"
                          placeholder="Sales Name / email"
                          type="text"
                          v-model="filter.name"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="employee_no">NIK</label>
                        <input
                          class="form-control"
                          id="employee_no"
                          name="employee_no"
                          placeholder="NIK"
                          type="text"
                          v-model="filter.employee_no"
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="firstname">Branch</label>
                        <model-select
                          :options="branches"
                          id="branch_id"
                          placeholder="select item"
                          v-model="filter.branch_id"
                        ></model-select>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="branch_id">Position</label>
                        <model-select
                          :options="occupations"
                          placeholder="select item"
                          v-model="filter.occupation_id"
                        ></model-select>
                      </div>
                    </div>
                    
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="firstname">Product Type</label>
                        <model-select
                          :options="model.product_type"
                          id="product_type"
                          placeholder="select item"
                          v-model="filter.product_type"
                        ></model-select>
                      </div>
                    </div>

                  </div>

                  <div class="row justify-content-end">
                    <div class="col-md-2">
                      <button
                        @click="applyFilterAllData"
                        class="btn bg-warning-700 btn-block rounded-round btn-labeled btn-labeled-left"
                        type="button"
                      >
                        <b>
                          <i class="icon-check"></i>
                        </b>
                        All Data
                      </button>
                    </div>
                    <div class="col-md-2">
                      <button
                        @click="applyFilter"
                        class="btn bg-green-700 btn-block rounded-round btn-labeled btn-labeled-left"
                        type="button"
                      >
                        <b>
                          <i class="icon-check"></i>
                        </b>
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>
                          <a
                            href="javascript:void(0)"
                            @click="sort($event, 'employee_no')"
                          >
                            NIK
                          </a>
                        </th>
                        <th>
                          <a
                            href="javascript:void(0)"
                            @click="sort($event, 'firstname')"
                          >
                            Name
                          </a>
                        </th>
                        <th>
                          <a
                            href="javascript:void(0)"
                            @click="sort($event, 'branch_id')"
                          >
                            Branch
                          </a>
                        </th>
                        <th>
                          <a
                            href="javascript:void(0)"
                            @click="sort($event, 'occupation_id')"
                          >
                            Occupation
                          </a>
                        </th>
                        <th>NR</th>
                        <th>AR</th>
                        <th>NC</th>
                        <th>NC Count</th>
                        <th>MA NC</th>
                        <th>NMI</th>
                        <th>LOT</th>
                        <th>ATA</th>
                        <th>Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="loading">
                        <tr :key="a" v-for="a in filter['per-page']">
                          <td :key="b" v-for="b in 12">
                            <content-placeholders :rounded="true">
                              <content-placeholders-text :lines="1" />
                            </content-placeholders>
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <template v-if="employees.length === 0">
                          <tr>
                            <td colspan="12">
                              <strong>No Data</strong>
                            </td>
                          </tr>
                        </template>
                        <template v-else>
                          <tr
                            v-for="employee in employees"
                            :key="employee.employee.id"
                          >
                            <td>
                              <div class="d-block">
                                {{ employee.employee.employee_no }}
                              </div>
                            </td>
                            <td>
                              <div class="d-block">
                                {{ employee.employee.firstname }}
                              </div>
                              <small class="text-muted">
                                {{ employee.employee.email }}
                              </small>
                            </td>
                            <td>{{ employee.employee.branch.name }}</td>
                            <td>{{ employee.employee.occupation.name }}</td>
                            <td>
                              <div class="d-block">
                                {{
                                  employee.achievements[0].total_new_referral | formatNum
                                }}
                              </div>

                              <small class="text-muted">
                                KPI :
                                {{
                                  employee.achievements[0].target_new_referral | formatNum
                                }}</small
                              >
                            </td>
                            <td>
                              <div class="d-block">
                                {{
                                  employee.achievements[0].total_active_referral | formatNum
                                }}
                              </div>

                              <small class="text-muted">
                                KPI :
                                {{
                                  employee.achievements[0]
                                    .target_active_referral | formatNum
                                }}</small
                              >
                            </td>

                            <td>
                              <div class="d-block">
                                {{ employee.achievements[0].total_nc | formatNum }}
                              </div>

                              <small class="text-muted">
                                KPI :
                                {{ employee.achievements[0].target_nc | formatNum }}</small
                              >
                            </td>

                            <td>
                              <div class="d-block">
                                {{ employee.achievements[0].total_nc_count | formatNum }}
                              </div>

                              <small class="text-muted">
                                KPI :
                                {{ employee.achievements[0].target_nc_count | formatNum }}</small
                              >
                            </td>
                            
                            <td>
                              <div class="d-block">
                                {{ employee.achievements[0].total_ma_nc | formatNum }}
                              </div>

                              <small class="text-muted">
                                KPI :
                                {{
                                  employee.achievements[0].target_ma_nc | formatNum
                                }}</small
                              >
                            </td>
                            <td>
                              <div class="d-block">
                                {{
                                  employee.achievements[0].total_net_achievement | formatNum
                                }}
                              </div>

                              <small class="text-muted">
                                KPI :
                                {{
                                  employee.achievements[0]
                                    .target_net_achievement | formatNum
                                }}</small
                              >
                            </td>
                            <td>
                              <div class="d-block">
                                {{ employee.achievements[0].total_lot | formatNum }}
                              </div>

                              <small class="text-muted">
                                KPI :
                                {{ employee.achievements[0].target_lot | formatNum }}</small
                              >
                            </td>
                            <td>
                              <div class="d-block">
                                {{
                                  employee.achievements[0]
                                    .total_active_trading_account | formatNum
                                }}
                              </div>

                              <small class="text-muted">
                                KPI :
                                {{
                                  employee.achievements[0]
                                    .target_active_trading_account | formatNum
                                }}</small
                              >
                            </td>
                            <td>
                              {{ employee.achievements[0].score | formatNum }}
                            </td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>

              <paginate
                :click-handler="clickCallback"
                :container-class="
                  'pagination pagination-separated pagination-rounded align-self-center'
                "
                :margin-pages="5"
                :next-class="'page-item'"
                :next-link-class="'page-link'"
                :next-text="'Next'"
                :page-class="'page-item'"
                :page-count="pagination.totalPage"
                :page-link-class="'page-link'"
                :page-range="5"
                :prev-class="'page-item'"
                :prev-link-class="'page-link'"
                :prev-text="'Prev'"
                v-if="!$root.mobile"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Kpi from '../apis/Kpi'
import moment from 'moment'
import Paginate from '../components/Paginate'
import AchievementBranch from '../mixins/AchievementBranch'
import Hrms from '../services/hrms'
import Master from '../services/master'
import { ModelSelect } from 'vue-search-select'
import BranchApi from '../apis/Branch'
import OccupationApi from '../apis/Occupation'
import Datepicker from 'vuejs-datepicker'

export default {
  mixins: [AchievementBranch],
  data() {
    return {
      occupations: [],
      branches: [],
      filter: {
        employee_no: null,
        name: null,
        email: null,
        branch_id: null,
        occupation_id: null,
        from_date: new Date()
          .toISOString()
          .replace(
            /^(?<year>\d+)-(?<month>\d+)-(?<day>\d+)T.*$/,
            '$<year>-$<month>-01'
          ),
        to_date: new Date()
          .toISOString()
          .replace(
            /^(?<year>\d+)-(?<month>\d+)-(?<day>\d+)T.*$/,
            '$<year>-$<month>-$<day>'
          ),
        'per-page': 10,
        order: 'firstname',
        direction: 'ASC',
        product_type: 'all'
      },
      loading: true,
      pagination: {
        totalPage: 1,
        currentPage: 1,
        totalData: 0,
        displaying: 0
      },
      loading: true,
      employees: [],
      model: {
        years: [],
        bulan: new Date().getMonth() + 1,
        tahun: new Date().getFullYear(),
        product_type:[
          {
            value: 'all',
            text: 'All'
          },
          {
            value:'MULTILATERAL',
            text: 'MULTILATERAL'
          },
          {
            value:'SPA',
            text: 'SPA'
          }
        ]
      }
    }
  },
  components: {
    Header: () =>
      import(
        /* webpackChunkName: "component-header" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/Header'
      ),
    Sidebar: () =>
      import(
        /* webpackChunkName: "component-sidebar" */
        /* webpackPrefetch: true */

        /* webpackMode: "eager" */
        '../components/Sidebar'
      ),
    Paginate,
    Datepicker,
    DownloadExcel: () =>
      import(
        /* webpackChunkName: "component-download-excel" */
        /* webpackPrefetch: true */
        /* webpackMode: "eager" */
        '../components/JsonExcel'
      ),
    ModelSelect
  },
  methods: {
    ymd(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    sort(e, field) {
      this.loading = true
      const search = {}
      search.page = 1
      this.filter.order = field
      if (this.filter.direction == 'ASC') {
        this.filter.direction = 'DESC'
      } else {
        this.filter.direction = 'ASC'
      }
      if (this.filter.employee_no) {
        search.employee_no = this.filter.employee_no.trim()
      }
      if (this.filter.name) {
        search.name = this.filter.name.trim()
      }
      if (this.filter.email) {
        search.email = this.filter.email.trim()
      }
      if (this.filter.branch_id) {
        search.branch_id = this.filter.branch_id
      }
      if (this.filter.occupation_id) {
        search.occupation_id = this.filter.occupation_id
      }
      if (this.filter.from_date) {
        search.from_date = moment(String(this.filter.from_date)).format(
          'YYYY-MM-DD'
        )
      }
      if (this.filter.to_date) {
        search.to_date = moment(String(this.filter.to_date)).format(
          'YYYY-MM-DD'
        )
      }
      search.sort = this.filter.order + ' ' + this.filter.direction
      search['per-page'] = this.filter['per-page']
      this.leagueTable(search)
    },
    clickCallback(e) {
      this.loading = true
      const search = {}
      search.page = e
      if (this.filter.direction == 'ASC') {
        this.filter.direction = 'DESC'
      } else {
        this.filter.direction = 'ASC'
      }
      if (this.filter.employee_no) {
        search.employee_no = this.filter.employee_no.trim()
      }
      if (this.filter.name) {
        search.name = this.filter.name.trim()
      }
      if (this.filter.email) {
        search.email = this.filter.email.trim()
      }
      if (this.filter.branch_id) {
        search.branch_id = this.filter.branch_id
      }
      if (this.filter.occupation_id) {
        search.occupation_id = this.filter.occupation_id
      }
      if (this.filter.from_date) {
        search.from_date = moment(String(this.filter.from_date)).format(
          'YYYY-MM-DD'
        )
      }
      if (this.filter.to_date) {
        search.to_date = moment(String(this.filter.to_date)).format(
          'YYYY-MM-DD'
        )
      }
      
      if (this.filter.product_type) {
        search.product_type = this.filter.product_type
      }
      search.sort = this.filter.order + ' ' + this.filter.direction
      search['per-page'] = this.filter['per-page']
      this.leagueTable(search)
    },
    applyFilter() {
      this.loading = true
      const search = {}
      search.page = 1
      if (this.filter.direction == 'ASC') {
        this.filter.direction = 'DESC'
      } else {
        this.filter.direction = 'ASC'
      }
      if (this.filter.employee_no) {
        search.employee_no = this.filter.employee_no.trim()
      }
      if (this.filter.name) {
        search.name = this.filter.name.trim()
      }
      if (this.filter.email) {
        search.email = this.filter.email.trim()
      }
      if (this.filter.branch_id) {
        search.branch_id = this.filter.branch_id
      }
      if (this.filter.occupation_id) {
        search.occupation_id = this.filter.occupation_id
      }
      if (this.filter.from_date) {
        search.from_date = moment(String(this.filter.from_date)).format(
          'YYYY-MM-DD'
        )
      }
      if (this.filter.to_date) {
        search.to_date = moment(String(this.filter.to_date)).format(
          'YYYY-MM-DD'
        )
      }
      
      if (this.filter.product_type) {
        search.product_type = this.filter.product_type
      }
      search.sort = this.filter.order + ' ' + this.filter.direction
      search['per-page'] = this.filter['per-page']
      this.leagueTable(search)
    },
    applyFilterAllData() {
      this.loading = true
      const search = {}
      search.page = 1
      if (this.filter.direction == 'ASC') {
        this.filter.direction = 'DESC'
      } else {
        this.filter.direction = 'ASC'
      }
      if (this.filter.employee_no) {
        search.employee_no = this.filter.employee_no.trim()
      }
      if (this.filter.name) {
        search.name = this.filter.name.trim()
      }
      if (this.filter.email) {
        search.email = this.filter.email.trim()
      }
      if (this.filter.branch_id) {
        search.branch_id = this.filter.branch_id
      }
      if (this.filter.occupation_id) {
        search.occupation_id = this.filter.occupation_id
      }
      if (this.filter.from_date) {
        search.from_date = moment(String(this.filter.from_date)).format(
          'YYYY-MM-DD'
        )
      }
      if (this.filter.to_date) {
        search.to_date = moment(String(this.filter.to_date)).format(
          'YYYY-MM-DD'
        )
      }
      
      if (this.filter.product_type) {
        search.product_type = this.filter.product_type
      }
      search.sort = this.filter.order + ' ' + this.filter.direction
      search['per-page'] = -1
      this.leagueTable(search)
    },
    listOccupation(params) {
      Hrms.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know. [CE-OCCUPATION-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      OccupationApi.search(params)
        .then(result => {
          this.occupations = result.data.data.map(x => {
            return {
              value: x.id,
              text: x.name
            }
          })
        })
        .catch(err => {
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'It is not possible for us to know. [CE-OCCUPATION-002]',
            'error'
          )
        })
    },
    listBranch() {
      Master.interceptors.response.use(
        response => response,
        error => {
          if (typeof error.response === 'undefined') {
            this.$swal(
              'Ooops!',
              'A network error occurred. ' +
                'This could be a CORS issue or a dropped internet connection. ' +
                'It is not possible for us to know. [CE-BRANCH-001]',
              'error'
            )
          }
          return Promise.reject(error)
        }
      )
      BranchApi.list()
        .then(result => {
          this.branches = result.data.data.map(x => {
            return {
              value: x.id,
              text: x.name
            }
          })
        })
        .catch(err => {
          console.log(err)
          this.$swal(
            'Ooops!',
            'A network error occurred. ' +
              'It is not possible for us to know. [CE-BRANCH-002]',
            'error'
          )
        })
    },
    leagueTable(query) {
      Kpi.leagueTable(query)
        .then(res => {
          this.employees = res.data.data
          this.pagination.totalPage = parseInt(res.data.meta.page_count)
          this.pagination.currentPage = parseInt(res.data.meta.current_page)
          this.pagination.totalData = parseInt(res.data.pages.totalCount)
          this.pagination.displaying = 0
          this.loading = false
          console.log(res)
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    }
  },
  mounted() {
    document.title = 'Achievement Table'

    this.listBranch({ limit: -1 })
    this.listOccupation({ limit: -1, department_id: 32 })
    this.leagueTable({ 'per-page': this.filter['per-page'] })
  },
  computed: {
    years() {
      const year = new Date().getFullYear()
      return Array.from({ length: year - 2018 }, (value, index) => 2019 + index)
    }
  },
  filters: {
    formatNum(value) {
      return new Intl.NumberFormat('id-ID').format(value)
    }
  }
}
</script>
