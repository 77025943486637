<template>
    <div>
        <Header>
            <template v-slot:nav-item-left>
                <li class="nav-item">
                    <router-link class="navbar-nav-link" to="/">Edit Kpi Channel</router-link>
                </li>
            </template>
        </Header>
        <div class="page-content">
            <Sidebar/>
            <div class="content-wrapper">
                <div class="content">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Channel</th>
                                        <th>KPI NR</th>
                                        <th>KPI AR</th>
                                        <th>KPI NC</th>
                                        <th>KPI NC Count</th>
                                        <th>KPI MA</th>
                                        <th>KPI NMI</th>
                                        <th>KPI Lot</th>
                                        <th>KPI ATA</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr :key="channel.channel.id" v-for="channel in kpi_channels">
                                        <td>{{ channel.channel.channel_name}}</td>
                                        <td>{{ channel.nr}}</td>
                                        <td>{{ channel.ar}}</td>
                                        <td>{{ channel.nc}}</td>
                                        <td>{{ channel.nc_count}}</td>
                                        <td>{{ channel.ma_nc}}</td>
                                        <td>{{ channel.nmi}}</td>
                                        <td>{{ channel.lot}}</td>
                                        <td>{{ channel.ata}}</td>
                                        <td class="text-right">
                                            <div class="list-icons">
                                                <div class="list-icons-item dropdown">
                                                    <a
                                                            class="list-icons-item dropdown-toggle caret-0"
                                                            data-toggle="dropdown"
                                                            href="javascript:void(0)"
                                                    >
                                                        <i class="fas fa-ellipsis-v text-green-700 fa-2x"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-menu-right">
                                                        <a @click="createOrUpdate(channel)" class="dropdown-item" href="javascript:void(0)">
                                                            <i class="far fa-copy"></i>
                                                            Edit Kpi
                                                        </a>
                                                        <a @click="copyChannel(channel)" class="dropdown-item" href="javascript:void(0)">
                                                            <i class="far fa-copy"></i>
                                                            Copy Kpi
                                                        </a>
                                                        <a @click="pasteChannel(channel)" class="dropdown-item" href="javascript:void(0)">
                                                            <i class="far fa-copy"></i>
                                                            Paste Kpi
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
                :hide-footer="true"
                :no-close-on-backdrop="true"
                :no-close-on-esc="true"
                header-class="bg-white"
                id="createOrUpdate"
                ref="createOrUpdate"
                title="Update KPI"
        >
            <form @submit.prevent="submitUpdateForm">
                <div class="row">
                    <div class="col-md-6 mb-2">
                        <label for="nr">NR</label>
                        <input class="form-control" id="nr" type="text" v-model="kpi_model.nr"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="ar">AR</label>
                        <input class="form-control" id="ar" type="text" v-model="kpi_model.ar"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="nc">NC</label>
                        <input class="form-control" id="nc" type="text" v-model="kpi_model.nc"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="ma_nc">MA</label>
                        <input class="form-control" id="ma_nc" type="text" v-model="kpi_model.ma_nc"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="nmi">NMI</label>
                        <input class="form-control" id="nmi" type="text" v-model="kpi_model.nmi"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="lot">LOT</label>
                        <input class="form-control" id="lot" type="text" v-model="kpi_model.lot"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="ata">ATA</label>
                        <input class="form-control" id="ata" type="text" v-model="kpi_model.ata"/>
                    </div>
                    <div class="col-md-6 mb-2">
                        <label for="ata">NC COUNT</label>
                        <input class="form-control" id="nc_count" type="text" v-model="kpi_model.nc_count"/>
                    </div>
                </div>
                <div class="form-group">
                </div>
                <button class="btn bg-green-700 btn-block mt-3" type="submit">Submit</button>
            </form>
        </b-modal>


    </div>
</template>
<script>
    import ChannelApi from '../apis/Channel'
    import KpiScoreChannelApi from '../apis/KpiScoreChannel'

    export default {
        data() {
            return {
                channels: [],
                kpi_channels: [],
                kpi_model: {
                    id: null,
                    kpi_id: null,
                    nr: 0,
                    ar: 0,
                    nc: 0,
                    nc_count: 0,
                    ma_nc: 0,
                    nmi: 0,
                    lot: 0,
                    ata: 0,
                    created_by: null,
                    created_at: null,
                    updated_by: null,
                    updated_at: null,
                    channel_id: null,
                    bulan: null,
                    tahun: null,
                    channel: {
                        id: null,
                        channel_code: null,
                        channel_name: null,
                        active: null
                    }
                },
                copy_model: {
                    id: null,
                    kpi_id: null,
                    nr: 0,
                    ar: 0,
                    nc: 0,
                    nc_count: 0,
                    ma_nc: 0,
                    nmi: 0,
                    lot: 0,
                    ata: 0,
                    created_by: null,
                    created_at: null,
                    updated_by: null,
                    updated_at: null,
                    channel_id: null,
                    bulan: null,
                    tahun: null,
                    channel: {
                        id: null,
                        channel_code: null,
                        channel_name: null,
                        active: null
                    }
                },
                filter: {
                    years: [],
                    bulan: this.$route.params.bulan,
                    tahun: this.$route.params.tahun
                }

            }
        },
        components: {
            Header: () =>
                import(
                    /* webpackChunkName: "component-header" */
                    /* webpackPrefetch: true */
                    /* webpackMode: "eager" */
                    '../components/Header'
                    ),
            Sidebar: () =>
                import(
                    /* webpackChunkName: "component-sidebar" */
                    /* webpackPrefetch: true */

                    /* webpackMode: "eager" */
                    '../components/Sidebar'
                    )
        },
        methods: {
            copyChannel(channel) {
                this.copy_model = channel
            },
            pasteChannel(channel) {
                if (channel.id) {
                    console.log('update');
                    let payload = this.copy_model;
                    payload.channel_id = channel.channel_id
                    delete payload.id
                    delete payload.created_at
                    delete payload.updated_at
                    console.log('channel', channel)
                    console.log('payload', payload)
                    this.kpiUpdate(channel.id, payload)
                    this.channelList()
                } else {
                    let payload = this.copy_model;
                    payload.channel_id = channel.channel_id
                    delete payload.id
                    delete payload.created_at
                    delete payload.updated_at
                    console.log('channel', channel)
                    console.log('payload', payload)
                    this.kpiStore(payload)
                    this.channelList()

                }
            },
            submitUpdateForm() {
                if (this.kpi_model.id) {
                    let payload = this.kpi_model
                    let id = payload.id
                    console.log('update', payload);
                    this.kpiUpdate(payload.id, payload);
                    this.$refs['createOrUpdate'].hide()
                    this.channelList()
                } else {
                    let payload = this.kpi_model
                    payload.bulan = this.filter.bulan
                    payload.tahun = this.filter.tahun
                    delete payload.id
                    delete payload.created_at
                    delete payload.updated_at
                    this.kpiStore(payload)
                    this.$refs['createOrUpdate'].hide()
                    this.channelList()
                }
            },
            createOrUpdate(channel) {
                this.kpi_model = channel
                this.$refs['createOrUpdate'].show()
            },
            channelList() {
                ChannelApi
                    .list()
                    .then(res => {
                        console.log(res)
                        this.channels = res.data.data
                        this.KpiScoreChannelApi({bulan: this.filter.bulan, tahun: this.filter.tahun})
                    })
                    .catch(res => {
                        console.log(res)
                    })
            },
            KpiScoreChannelApi(query) {
                KpiScoreChannelApi
                    .channelByMonthYear(query)
                    .then(res => {
                        this.kpi_channels = res.data.data
                    })
                    .catch(res => {
                        console.log(res)
                    })
            },
            kpiStore(payload) {
                KpiScoreChannelApi
                    .store(payload)
                    .then(res => {
                        console.log(res)
                    })
                    .catch(res => {
                        console.log(res)
                    })
            },
            kpiUpdate(id, payload) {
                KpiScoreChannelApi
                    .update(payload, id)
                    .then(res => {
                        console.log(res)
                    })
                    .catch(res => {
                        console.log(res)
                    })
            }
        },
        mounted() {
            document.title = 'Edit Kpi Channel'
            this.channelList()
        }
    }
</script>